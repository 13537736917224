
import {
  ComputedRef,
  computed,
  defineComponent,
  reactive,
  ref,
  Ref,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import useForm, { FormFieldDict } from '@/use/useForm';

import Button from '@/components/Button/Button.vue';
import Input from '@/components/Input/Input.vue';
import Voucher from '@/components/Voucher/Voucher.vue';
import InlineNotification, {
  NOTIFICATION_TYPES,
} from '@/components/InlineNotification/InlineNotification.vue';
import LinkButton from '@/components/LinkButton/LinkButton.vue';
import Select from '@/components/Select/Select.vue';

import { Product, Variant, PRODUCT_TYPES } from '@/modules/shop/types';
import { Consumer } from '@/modules/consumer/types';

import ShopFaq from '@/modules/shop/components/ShopFaq/ShopFaq.vue';
import CheckoutHeader from '@/modules/shop/components/CheckoutHeader/CheckoutHeader.vue';
import CheckoutSteps from '@/modules/shop/components/CheckoutSteps/CheckoutSteps.vue';
import CheckoutCard from '@/modules/shop/components/CheckoutCard/CheckoutCard.vue';

const validators = {
  notEmptyField: (fieldName: string) => (value) =>
    !value && `Bitte gib eine ${fieldName} an`,
  isNumber: (fieldName: string) => (value) =>
    isNaN(value) && `Die ${fieldName} muss eine Zahl sein`,
  isExactLength: (amount: number) => (value: string) =>
    value?.length !== amount &&
    'Die Postleitzahl muss eine 5-stellige Zahl sein',
};

const NOTIFICATION_TIMEOUT = 4000;

export default defineComponent({
  components: {
    ShopFaq,
    Button,
    CheckoutCard,
    CheckoutHeader,
    CheckoutSteps,
    InlineNotification,
    Input,
    LinkButton,
    Voucher,
    Select,
  },
  props: {
    product: {
      type: Object as () => Product,
      required: true,
    },
    additionalProduct: {
      type: Object as () => Product,
      required: false,
    },
    variant: {
      type: Object as () => Variant,
      required: true,
    },
    orderAction: {
      type: Function,
      required: true,
    },
    loyaltyPoints: {
      type: Number,
      required: true,
    },
    consumer: {
      type: Object as () => Consumer,
      required: true,
    },
    networkError: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const options = computed(() =>
      Array.from(
        { length: props.additionalProduct?.orderLimit },
        (_, i) => i + 1
      )
    );

    const AGB_URL =
      'https://www.iqosclub.com/de/de/terms-and-conditions-iqos-club.html';
    const isShippableProduct: ComputedRef<boolean> = computed(() =>
      [PRODUCT_TYPES.ITG, PRODUCT_TYPES.ARVATO].includes(props.product.type)
    );

    const shippableProductsFormFields = {
      extraAdress: {
        currentValue: '',
      },
      street: {
        currentValue: '',
        validators: [validators.notEmptyField('Straße')],
      },
      houseNumber: {
        currentValue: '',
        validators: [validators.notEmptyField('Hausnummer')],
      },
      postalCode: {
        currentValue: '',
        validators: [
          validators.notEmptyField('PLZ'),
          validators.isNumber('PLZ'),
          validators.isExactLength(5),
        ],
      },
      city: {
        currentValue: '',
        validators: [validators.notEmptyField('Stadt')],
      },
    };

    const formConfig: FormFieldDict = reactive({
      firstName: {
        currentValue: props.consumer.firstName,
      },
      lastName: {
        currentValue: props.consumer.lastName,
      },
      ...(isShippableProduct.value ? shippableProductsFormFields : {}),
    });

    const form = useForm(formConfig);

    const additionalProductNumber = ref(null);
    const isAdditionalProductConfirmed = ref(null);
    const isFormButtonEnabled = ref(true);
    const enableButton = () => (isFormButtonEnabled.value = true);
    const disableButton = () => (isFormButtonEnabled.value = false);

    const validateAndSubmit = () => {
      if (form.isValid()) {
        disableButton();
        const orderProducts = isAdditionalProductConfirmed.value
          ? {
              ...form.getValues(),
              ...{
                additionalProductId: props.additionalProduct?.productId,
                additionalVariantId:
                  props.additionalProduct?.variants[0]?.variantId,
                additionalAmount: additionalProductNumber.value,
              },
            }
          : form.getValues();
        props.orderAction(orderProducts).finally(enableButton);
      }
    };

    const warnings = ref([false, false]);
    const showWarning = (warningIndex): void => {
      warnings.value[warningIndex] = true;
      setTimeout(
        () => (warnings.value[warningIndex] = false),
        NOTIFICATION_TIMEOUT
      );
    };

    const shouldDisplayNetworkError: Ref<boolean> = ref(false);
    watchEffect(() => {
      if (props.networkError) {
        shouldDisplayNetworkError.value = true;
        setTimeout(
          () => (shouldDisplayNetworkError.value = false),
          NOTIFICATION_TIMEOUT
        );
      }
    });

    const onSelectOption = async (option) => {
      additionalProductNumber.value = option;
      await store.dispatch(
        'shopModule/setAdditionalProducts',
        props.additionalProduct
      );
    };

    const onClickGetPoints = async () => {
      if (!isAdditionalProductConfirmed.value) {
        await store.dispatch('shopModule/resetAdditionalProducts');
      }
    };

    const onClickAdditionalProduct = () => {
      isAdditionalProductConfirmed.value = !!additionalProductNumber.value;
    };

    return {
      options,
      AGB_URL,
      NOTIFICATION_TYPES,
      form,
      isShippableProduct,
      showWarning,
      validateAndSubmit,
      warnings,
      isFormButtonEnabled,
      shouldDisplayNetworkError,
      onSelectOption,
      onClickGetPoints,
      isAdditionalProductConfirmed,
      additionalProductNumber,
      onClickAdditionalProduct,
    };
  },
});
